import { LocalStorageKeys } from '.'

const KEY_PREFIX = 'nutrisense:'

const generateKey = (key: LocalStorageKeys) => `${KEY_PREFIX}${key}`

export const getItem = (key: LocalStorageKeys) => localStorage.getItem(generateKey(key))

export const setItem = (key: LocalStorageKeys, value: string) =>
  localStorage.setItem(generateKey(key), value)

export const removeItem = (key: LocalStorageKeys) => localStorage.removeItem(generateKey(key))

export const getObject = <T>(key: LocalStorageKeys): T | null => {
  try {
    const payload = getItem(key)

    if (!payload) return null

    return JSON.parse(payload)
  } catch (error) {
    return null
  }
}

export const setObject = (key: LocalStorageKeys, object: { [key: string]: unknown }) => {
  setItem(key, JSON.stringify(object))
}
