import { isVideoCallsFlow } from 'utils/navigation'
import { User } from 'types'

export const isBookingAllowed = (user?: User | null) => {
  if (!user) {
    return false
  }
  const isNewVideoCallsFlow = isVideoCallsFlow()

  if (!isNewVideoCallsFlow) {
    return true
  }

  return !!user.paymentMethod && !!user.address
}
