import { AddressState } from 'types'
import { getObject, LocalStorageKeys, setObject } from './storage'

interface UserData {
  email?: string
  dateOfBirth?: Date
  state?: AddressState
  termsOfUseConsent?: boolean
}

export const getUserData = (): UserData | null => getObject<UserData>(LocalStorageKeys.UserData)

export const setUserData = (userData: Partial<UserData>) => {
  const currentUserData = getUserData()

  setObject(LocalStorageKeys.UserData, { ...currentUserData, ...userData })
}
