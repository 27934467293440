import sha256 from 'crypto-js/sha256'
import { User } from 'types'
import { GoogleTagManagerUser } from './types'

export const transformIntoGoogleTagManagerUser = (user: Partial<User>): GoogleTagManagerUser => {
  const email = user.email?.trim().toLowerCase() || ''
  // we don't want sha256 of an empty string
  const userEmailSha256 = email ? sha256(email).toString() : ''
  const phoneNumber = user.phoneNumber
  const phoneNumberSha256 = phoneNumber ? sha256(phoneNumber).toString() : undefined

  return {
    userEmail: email,
    userFirstName: user.firstName,
    userLastName: user.lastName,
    userPhoneNumber: phoneNumber,
    userZipCode: user.address?.zipCode,
    userEmailSha256: userEmailSha256,
    userPhoneNumberSha256: phoneNumberSha256,
    userAddressStreet: user.address?.street,
    userAddressCity: user.address?.city,
    userAddressState: user.address?.state
  }
}
