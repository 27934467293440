import { UserEvents } from 'hooks/analytics'
import { transformIntoGoogleTagManagerUser } from 'utils/analytics'
import { User } from 'types'
import { useGoogleTagManagerTrack } from './useGoogleTagManagerTrack'

export const useGoogleTagManagerIdentifyUser = () => {
  const track = useGoogleTagManagerTrack()

  const identify = (user: Partial<User>) => {
    if (!user.email) throw new Error('We cannot identify user without email')

    track(UserEvents.Identify, transformIntoGoogleTagManagerUser(user))
  }

  return identify
}
