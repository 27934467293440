import { forwardRef } from 'react'
import { InlineAlert, InlineAlertProps } from 'components'

const Alert = forwardRef<HTMLDivElement, InlineAlertProps>((props, ref) => {
  return <InlineAlert elevation={6} ref={ref} variant="filled" {...props} />
})

Alert.displayName = 'Alert'

export { Alert }
