export const palette = {
  members: {
    background: '#F3F1EE',
    primary: '#182E31',
    accent: {
      main: '#FACE46',
      light: '#FACE46',
      dark: '#FFF3CD',
      text: '#574717'
    },
    input: {
      hover: '#F9F8F7',
      focus: '#15707A'
    },
    button: {
      hover: '#245157',
      selected: '#4A7E84'
    },
    shadow: '#DAE7E8',
    placeholder: '#B3B3B3',
    stroke: '#E0DCD7',
    pill: '#E7E4E0',
    pillIcon: '#AC9E92'
  },
  brandPrimary: {
    base: {
      main: '#102F32',
      light: '#102F32',
      dark: '#1A7079'
    },
    light: {
      main: '#11494F',
      light: '#11494F',
      dark: '#11494F'
    },
    dark: {
      main: '#0D363A',
      light: '#0D363A',
      dark: '#0D363A'
    }
  },
  brandSecondary: {
    base: {
      main: '#A6B8B9',
      light: '#A6B8B9',
      dark: '#272C2E'
    },
    darker: {
      main: '#EFE6CF',
      light: '#EFE6CF',
      dark: '#EFE6CF'
    }
  },
  brandText: {
    lightest: {
      main: '#98B7B4',
      light: '#98B7B4',
      dark: '#FFFFFF'
    },
    lighter: {
      main: '#658D89',
      light: '#658D89',
      dark: '#FFFFFF'
    },
    light: {
      main: '#416461',
      light: '#416461',
      dark: '#FFFFFF'
    },
    link: {
      main: '#136EF5'
    },
    base: {
      main: '#102F32',
      light: '#102F32',
      dark: '#FFFFFF'
    },
    dark: {
      main: '#07292D',
      light: '#07292D',
      dark: '#B5B5B6'
    },
    darker: {
      main: '#032124',
      light: '#032124',
      dark: '#858586'
    },
    darkest: {
      main: '#151818',
      light: '#151818',
      dark: '#3F3E3E'
    }
  },
  brandGrey: {
    lightest: '#F7F9FA',
    lighter: '#F2F4F5',
    light: '#E7EAEA',
    base: '#D7DDDD',
    dark: '#9DAEAE'
  },
  brandAccent: {
    teaGreen: '#C2D0AE',
    citron: '#EFD482'
  },
  tooltip: {
    surface: '#57626D'
  },
  success: {
    base: '#21BA67',
    light: '#4CD471',
    lighter: '#7DDE86',
    lightest: '#ECFCE5',
    main: '#21BA67',
    dark: '#558D20',
    darkest: '#198155'
  },
  info: {
    lightest: '#C9F0FF',
    lighter: '#9BDCFD',
    darkest: '#0065D0',
    light: '#94CBFF',
    main: '#2F9BE8',
    dark: '#0057C2'
  },
  warning: {
    lightest: '#FFEFD7',
    light: '#FFE59E',
    main: '#FFBB36',
    dark: '#B86E00',
    darkest: '#C6770C'
  },
  error: {
    lightest: '#FFE5E5',
    light: '#FA5764',
    main: '#F83342',
    dark: '#D3180C',
    darkest: '#D3180C'
  },
  chip: {
    primary: '#B8EACD',
    secondary: '#FAE7AF'
  }
}
