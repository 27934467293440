import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import styled from 'styled-components'

export const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  backgroundColor: 'transparent',
  boxShadow: 'none',
  margin: 0,
  '&::before': {
    display: 'none'
  }
}))
