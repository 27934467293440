import { createContext, ReactNode, useContext, useEffect, useState, useCallback } from 'react'
import { ServerError } from '@apollo/client/link/utils'
import { cache } from 'config/apollo/client'
import { analytics } from 'config/segment'
import {
  useCurrentUserQuery,
  useSignOutUserMutation,
  useSignInPasswordlessUserMutation,
  SignInPasswordlessUserMutation,
  User
} from 'types'
import { showSnack } from './ui'

export interface LoginData {
  email: string
  password: string
}

const NOT_INITIALIZED = 'Waiting for auth provider'
const INVALID_CREDENTIALS = 'Token expired, please request a new link'
const LOGIN_FAILED = 'An unknown error occurred, please try again'
const NETWORK_ISSUE = 'A network issue occurred, please try again'

interface AuthContextValues {
  loading: boolean
  isAuthorized: boolean
  isInitializing: boolean
  isSigningIn: boolean
  currentUser: User | null | undefined
  passwordlessSignIn: (signInToken: string) => Promise<SignInPasswordlessUserMutation | void>
  signOut: () => Promise<void>
}

const defaultValues: AuthContextValues = {
  loading: true,
  isAuthorized: false,
  isInitializing: true,
  isSigningIn: false,
  currentUser: undefined,
  passwordlessSignIn: async (_: string): Promise<SignInPasswordlessUserMutation | void> => {
    throw NOT_INITIALIZED
  },
  signOut: async (): Promise<void> => {}
}

const AuthContext = createContext(defaultValues)
export const useAuth = () => useContext(AuthContext)

export enum UserRoles {
  Guest = 'guest',
  User = 'user',
  Admin = 'admin'
}

interface AuthProviderProps {
  children: ReactNode
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const { data: { currentUser } = {}, loading: isInitializing } = useCurrentUserQuery()
  const [loggedIn, setLoggedIn] = useState(false)
  const [isSigningIn, setSigningIn] = useState(false)

  const [passwordlessSignInMutation] = useSignInPasswordlessUserMutation({
    onError: (e) => {
      if (e.networkError && (e.networkError as ServerError).statusCode === 401) {
        throw INVALID_CREDENTIALS
      }
      throw NETWORK_ISSUE
    }
  })

  const [signOutMutation] = useSignOutUserMutation({
    onError: () => {
      showSnack('Unable to sign out. Please try again later.', 'error')
    }
  })

  useEffect(() => {
    setLoggedIn(!!currentUser)
  }, [currentUser])

  const passwordlessSignIn = useCallback(
    async (signInToken: string): Promise<SignInPasswordlessUserMutation | void> => {
      setSigningIn(true)
      try {
        const { data: response } = await passwordlessSignInMutation({
          variables: { signInToken }
        })

        if (!response) {
          throw LOGIN_FAILED
        }

        const { success = false, token } = response.signInPasswordlessUser || {}

        if (!token) {
          throw INVALID_CREDENTIALS
        } else if (!success) {
          throw LOGIN_FAILED
        }

        setLoggedIn(true)
        return response
      } finally {
        setSigningIn(false)
      }
    },
    [setSigningIn, setLoggedIn, passwordlessSignInMutation]
  )

  const signOut = useCallback(async () => {
    await signOutMutation()
    analytics.reset()
    localStorage.clear()
    setLoggedIn(false)

    if (currentUser?.id) {
      const normalizedId = cache.identify({ id: currentUser.id, __typename: 'User' })
      cache.evict({ id: normalizedId })
      cache.gc()
    }
  }, [signOutMutation, currentUser?.id])

  const value: AuthContextValues = {
    loading: isInitializing || isSigningIn,
    isAuthorized: loggedIn || !!currentUser,
    isInitializing,
    isSigningIn,
    currentUser: currentUser as User,
    passwordlessSignIn,
    signOut
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
