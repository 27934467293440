import { useEffect, useState } from 'react'
import { millisecondsToMinutes } from 'date-fns'
import { getVideoCallData } from 'screens/VideoCalls/utils'

const RESERVATION_TIME = 15 * 60 * 1000 // 15 minutes
const INTERVAL = 1000 // 1 second

const formatTime = (time: number) => {
  // prepend leading 0 if necessary
  if (time < 10) {
    return `0${time}`
  }

  return `${time}`
}

export const useReservationTimer = () => {
  const slotReservedAt = getVideoCallData()?.slot_reserved_at
  const expirationTime = slotReservedAt
    ? new Date(slotReservedAt).getTime() + RESERVATION_TIME
    : new Date().getTime()

  const [countDownTime, setCountDownTime] = useState(expirationTime - new Date().getTime())

  const expired = slotReservedAt && expirationTime < new Date().getTime()

  useEffect(() => {
    if (!slotReservedAt || expired) {
      return
    }

    const intervalId = setInterval(() => {
      setCountDownTime(expirationTime - new Date().getTime())
      if (expired) {
        clearInterval(intervalId)
      }
    }, INTERVAL)

    return () => {
      clearInterval(intervalId)
    }
  }, [expirationTime, expired, setCountDownTime, slotReservedAt])

  if (!slotReservedAt) {
    return {
      shouldShow: false,
      expired,
      timeLeft: ''
    }
  }

  const minutes = millisecondsToMinutes(countDownTime)
  const seconds = Math.floor((countDownTime % (1000 * 60)) / 1000)
  const timeLeft = `${formatTime(minutes)}:${formatTime(seconds)}`

  return {
    shouldShow: true,
    expired,
    timeLeft
  }
}
