import { useEffect } from 'react'
import { analytics } from 'config/segment'
import { useCurrentUserQuery } from 'types'

export const Segment = () => {
  const { data: { currentUser } = {} } = useCurrentUserQuery()

  const currentUserId = currentUser?.id

  useEffect(() => {
    if (!currentUserId) {
      return
    }

    analytics.identify(currentUserId)
  }, [currentUserId])

  return null
}
