import { IMutableContext, UnleashClient } from '@unleash/proxy-client-react'
import { env } from 'config/env'
import config from 'config/unleash'

const client = new UnleashClient(config)

const buildContext = (userId?: string | undefined, userEmail: string = ''): IMutableContext => ({
  userId,
  properties: {
    appBuildEnv: env.REACT_APP_ENV,
    userEmail
  }
})

client.start()

export { client, buildContext }
