import { Suspense } from 'react'
import { Box, Grid, Stack } from '@mui/material'
import { Loader } from 'components'
import { LocationPaths } from 'location.types'
import { Outlet, useLocation } from 'react-router-dom'
import { palette } from 'theme/theme.palette'

const Loading = () => {
  return (
    <Box style={{ height: '100vh' }}>
      <Loader />
    </Box>
  )
}

export const PageLayout = () => {
  const location = useLocation()

  return (
    <Grid
      container
      sx={{
        flexWrap: 'nowrap',
        backgroundColor:
          location.pathname.startsWith(LocationPaths.VideoCalls) ||
          location.pathname.startsWith(LocationPaths.OwnSensor)
            ? palette.members.background
            : undefined
      }}
    >
      <Grid flex={1} item>
        <Stack minHeight="100dvh">
          <Suspense fallback={<Loading />}>
            <Outlet />
          </Suspense>
        </Stack>
      </Grid>
    </Grid>
  )
}
