import React from 'react'
import { ApolloProvider } from '@apollo/client'
import { Client } from '@bugsnag/js'
import { CssBaseline, GlobalStyles, StyledEngineProvider, ThemeProvider } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { Segment } from 'components'
import { Bugsnag as BugsnagProvider, GoogleTagManager } from 'components/Analytics'
import { client } from 'config/apollo/client'
import bugsnagClient from 'config/bugsnag'
import { AuthProvider, UtmParamsProvider, UnleashProvider } from 'context'
import { ConfirmProvider } from 'material-ui-confirm'
import { createRoot } from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import { App } from './App'
import { theme, globalStyles } from './theme'
import './index.css'

const container = document.getElementById('root')!
const root = createRoot(container)

root.render(
  <React.StrictMode>
    <Router>
      <ApolloProvider client={client}>
        <BugsnagProvider client={bugsnagClient as unknown as Client}>
          <UnleashProvider>
            <UtmParamsProvider>
              <AuthProvider>
                <StyledEngineProvider injectFirst>
                  <ThemeProvider theme={theme}>
                    <ConfirmProvider>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <CssBaseline />
                        <GlobalStyles styles={globalStyles} />
                        <Segment />
                        <GoogleTagManager />
                        <App />
                      </LocalizationProvider>
                    </ConfirmProvider>
                  </ThemeProvider>
                </StyledEngineProvider>
              </AuthProvider>
            </UtmParamsProvider>
          </UnleashProvider>
        </BugsnagProvider>
      </ApolloProvider>
    </Router>
  </React.StrictMode>
)
