import { ReactNode } from 'react'
import { Client } from '@bugsnag/browser'
import { useBugsnagInit } from 'hooks/analytics/bugsnag/useBugsnagInit'

interface BugsnagProviderProps {
  client: Client | undefined
  children: ReactNode | ReactNode[] | null
}

export const Bugsnag = ({ client, children }: BugsnagProviderProps) => {
  useBugsnagInit(client)
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}
