import { ComponentType, lazy, LazyExoticComponent } from 'react'

export interface LazyComponentWithPreload<T extends ComponentType<any>>
  extends LazyExoticComponent<T> {
  preload: () => Promise<{ default: T }>
}

export const lazyPreload = <T extends ComponentType<any>>(
  importStatement: () => Promise<{ default: T }>
): LazyComponentWithPreload<T> => {
  const Component = lazy(importStatement) as LazyComponentWithPreload<T>
  Component.preload = importStatement
  return Component
}
