import { SyntheticEvent, useEffect, useState } from 'react'
import { useReactiveVar } from '@apollo/client'
import { Snackbar, SnackbarCloseReason } from '@mui/material'
import { snackBarVisibleVar } from 'context'
import { Alert } from './Alert'
import { TransitionUp } from './TransitionUp'

const DEFAULT_VERTICAL_PLACEMENT = 'bottom'
const DEFAULT_HORIZONTAL_PLACEMENT = 'right'
const DEFAULT_SEVERITY = 'error'
const DEFAULT_MESSAGE = 'An error has occurred. Please try again later.'

export const SnackBar = () => {
  const {
    open,
    vertical = DEFAULT_VERTICAL_PLACEMENT,
    horizontal = DEFAULT_HORIZONTAL_PLACEMENT,
    severity = DEFAULT_SEVERITY,
    message = DEFAULT_MESSAGE,
    handleClose = undefined
  } = useReactiveVar(snackBarVisibleVar)

  // local state necessary to prevent snackbar flicker; during the onClose animation the values for the snackbar would return to the default ones and give undesired visual effect.
  const [snackMessage, setSnackMessage] = useState(message)
  const [snackSeverity, setSnackSeverity] = useState(severity)

  useEffect(() => {
    if (message !== DEFAULT_MESSAGE) {
      setSnackMessage(message)
    }

    if (severity !== snackSeverity) {
      setSnackSeverity(severity)
    }
  }, [message, severity, snackSeverity])

  const handleCloseSnackBar = (_: SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return
    }

    if (typeof handleClose === 'function') {
      handleClose()
    }

    snackBarVisibleVar({ open: false, severity: snackSeverity })
  }

  return (
    <Snackbar
      sx={{ maxWidth: '40%' }}
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      onClose={handleCloseSnackBar}
      TransitionComponent={TransitionUp}
      autoHideDuration={4000}
      key={vertical + horizontal}
    >
      <Alert severity={snackSeverity}>{snackMessage}</Alert>
    </Snackbar>
  )
}
