import { ApolloClient, ApolloLink, InMemoryCache, ServerParseError, from } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import Bugsnag from '@bugsnag/js'
import { createUploadLink } from 'apollo-upload-client'
import { env } from 'config'
import { GlpPath, LocationPaths, OwnSensorPath, SearchParams, VideoCallsPath } from 'location.types'
import { surveyScreenPath } from 'utils/navigation'
import { SurveysConfigKind } from 'types'
import { clientLink } from './clientLink'
import { userIdLink } from './userIdLink'

export const uri = env.REACT_APP_API_ENDPOINT
const redirectToLoginErrorCodes = [401, 403]
const invalidSessionErrorCode = 401

const errorLink = onError(({ networkError }) => {
  if (networkError) {
    if ((networkError as ServerParseError).statusCode !== invalidSessionErrorCode) {
      Bugsnag.notify(networkError)
    }
    if (redirectToLoginErrorCodes.includes((networkError as ServerParseError).statusCode)) {
      maybeNavigateToLogin()
    }
  }
})

const EXACT_PUBLIC_PATHS: string[] = [
  LocationPaths.Auth,
  LocationPaths.SignIn,
  LocationPaths.Dpp,
  LocationPaths.Glp,
  `${LocationPaths.Glp}/${GlpPath.Goal}`,
  `${LocationPaths.Glp}/${GlpPath.TriedLoseWeight}`,
  `${LocationPaths.Glp}/${GlpPath.NewApproach}`,
  `${LocationPaths.Glp}/${GlpPath.State}`,
  `${LocationPaths.Glp}/${GlpPath.DateOfBirth}`,
  `${LocationPaths.Glp}/${GlpPath.MedicalHistory}`,
  `${LocationPaths.Glp}/${GlpPath.Bmi}`,
  `${LocationPaths.Glp}/${GlpPath.Descent}`,
  `${LocationPaths.Glp}/${GlpPath.Conditions}`,
  `${LocationPaths.Glp}/${GlpPath.WeightLossAmount}`,
  `${LocationPaths.Glp}/${GlpPath.Ineligible}`,
  `${LocationPaths.Glp}/${GlpPath.NotRightProgram}`,
  `${LocationPaths.Glp}/${GlpPath.BmiTooLow}`,
  `${LocationPaths.Glp}/${GlpPath.SignUp}`,
  LocationPaths.OwnSensor,
  `${LocationPaths.OwnSensor}/${OwnSensorPath.ChooseHealthGoal}`,
  `${LocationPaths.OwnSensor}/${OwnSensorPath.WeightLossQuote}`,
  `${LocationPaths.OwnSensor}/${OwnSensorPath.MobileOperatingSystem}`,
  `${LocationPaths.OwnSensor}/${OwnSensorPath.ChooseSensor}`,
  `${LocationPaths.OwnSensor}/${OwnSensorPath.EligibilityCheck}`,
  LocationPaths.VideoCalls,
  `${LocationPaths.VideoCalls}/${VideoCallsPath.State}`,
  `${LocationPaths.VideoCalls}/${VideoCallsPath.SignUp}`,
  `${LocationPaths.VideoCalls}/${VideoCallsPath.ScheduleCall}`,
  `${LocationPaths.VideoCalls}/${VideoCallsPath.AddAppointmentAgenda}`,
  `${LocationPaths.VideoCalls}/${VideoCallsPath.Insurance}`,
  `${LocationPaths.VideoCalls}/${VideoCallsPath.EmailAddress}`,
  `${LocationPaths.VideoCalls}${surveyScreenPath(SurveysConfigKind.Insurance)}`
]

const REGEX_PUBLIC_PATHS: RegExp[] = [/^\/questionnaire\/.*/]

const maybeNavigateToLogin = () => {
  const pathname = window.location.pathname.endsWith('/')
    ? window.location.pathname.slice(0, -1)
    : window.location.pathname

  if (
    EXACT_PUBLIC_PATHS.includes(pathname) ||
    REGEX_PUBLIC_PATHS.some((path) => pathname.match(path))
  ) {
    return
  }

  const searchParams = new URLSearchParams(window.location.search)

  const token = searchParams.get(SearchParams.Token)

  if (token) {
    window.location.replace(
      `${LocationPaths.Auth}?token=${token}&target_path=${window.location.pathname}`
    )
  } else {
    window.location.replace(`${LocationPaths.SignIn}?target_path=${window.location.pathname}`)
  }
}

const uploadLink = createUploadLink({
  uri,
  credentials: 'include'
}) as unknown as ApolloLink

export const client = new ApolloClient({
  link: from([clientLink, userIdLink, errorLink, uploadLink]),
  cache: new InMemoryCache()
})

export const cache = client.cache
