import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  LatestUserAppointment,
  useCurrentUserQuery,
  useLatestUserAppointmentQuery,
  User
} from 'types'
import { VideoCallFlow } from '../types'
import { findNextStep } from '../utils'

export const useStepNavigation = (currentStep: number) => {
  const navigate = useNavigate()
  const { data: { currentUser } = {} } = useCurrentUserQuery()
  const { data: { latestUserAppointment } = {} } = useLatestUserAppointmentQuery()

  const navigateToStep = useCallback(
    (stepChange: number) => {
      const targetStep = VideoCallFlow[currentStep + stepChange]
      if (!targetStep) {
        return
      }

      return setTimeout(() => navigate(targetStep.location), 200)
    },
    [currentStep, navigate]
  )

  const navigateToNextStep = () => {
    const nextStep = findNextStep(
      currentStep,
      currentUser as User,
      latestUserAppointment as LatestUserAppointment
    )

    navigate(nextStep.location)
  }

  const navigateToPreviousStep = () => navigateToStep(-1)

  return {
    navigateToPreviousStep,
    navigateToNextStep
  }
}
