import { FC, createContext, useContext, useEffect, useState } from 'react'
import Bugsnag from '@bugsnag/js'

import { Box, Typography } from '@mui/material'

import { Loader } from 'components'
import LockIcon from 'components/Icons/LockIcon'
import { Header } from 'components/PageLayout/Header'
import { usePageTracking } from 'hooks/analytics'
import { LocationPaths } from 'location.types'
import { useLocation, useNavigate } from 'react-router-dom'
import { FlowProgress } from 'screens/VideoCalls/FlowProgress'
import { useStepNavigation, useReferredByCoach } from 'screens/VideoCalls/hooks'
import { useReferralPartner } from 'screens/VideoCalls/hooks/useReferralPartner'
import { GlobalTimer } from 'screens/VideoCalls/ReservationTimer'
import { VideoCallFlow } from 'screens/VideoCalls/types'
import { findNextStep } from 'screens/VideoCalls/utils'
import {
  LatestUserAppointment,
  MobileAppFeature,
  useCurrentUserQuery,
  useLatestUserAppointmentQuery,
  User
} from 'types'

const defaultValues = {
  stepIndex: -1,
  navigateToNextStep: () => {},
  registerCallback: (_cb: () => void) => {}
}

const VideoCallFlowContext = createContext(defaultValues)
export const useVideoCallsFlowContext = () => useContext(VideoCallFlowContext)

export const VideoCallFlowStep: FC<any> = ({ stepIndex }) => {
  const trackPage = usePageTracking()
  const navigate = useNavigate()
  const location = useLocation()
  const { capturePartner } = useReferralPartner()
  const { captureCoachId } = useReferredByCoach()

  const { data: { currentUser } = {}, loading: loadingUser } = useCurrentUserQuery()
  const { data: { latestUserAppointment } = {}, loading: loadingAppointment } =
    useLatestUserAppointmentQuery()

  const { navigateToNextStep, navigateToPreviousStep } = useStepNavigation(stepIndex)
  const [callback, setCallback] = useState<(() => void) | null>(null)

  const currentStep = VideoCallFlow[stepIndex]
  const loading = loadingUser || loadingAppointment

  const StepComponent = currentStep.routeComponent

  useEffect(() => {
    if (loading) {
      return
    }
    const nextStep = findNextStep(
      stepIndex,
      currentUser as User,
      latestUserAppointment as LatestUserAppointment
    )

    if (nextStep && nextStep.routeComponent.preload) {
      nextStep.routeComponent.preload()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, loading])

  useEffect(() => {
    if (!currentStep) {
      Bugsnag.notify(new Error('Invalid step index'))
      navigate(LocationPaths.NotFound)
    }
  }, [currentStep, navigate])

  useEffect(() => {
    trackPage(location.pathname)
  }, [location.pathname, trackPage])

  useEffect(() => {
    const currentStepCompleted = currentStep.completedResolver(
      currentUser as User,
      latestUserAppointment as LatestUserAppointment
    )

    if (!loading && currentStepCompleted) {
      const nextStep = findNextStep(
        stepIndex,
        currentUser as User,
        latestUserAppointment as LatestUserAppointment
      )

      navigate(nextStep.location)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, latestUserAppointment])

  if (stepIndex === 0) {
    capturePartner()
    captureCoachId()
  }

  const registerCallback = (callback: () => void) => {
    setCallback(() => callback)
  }

  const handlePreviousStepNavigate = () => {
    if (callback) {
      callback()
    }
    navigateToPreviousStep()
  }

  const alreadyEligible = currentUser?.features.includes(
    MobileAppFeature.InsuranceCoveredVideoCalls
  )

  // Hide the back button for users that are already eligible (i.e. users that became eligible via a different flow, e.g. DPP).
  // Otherwise, they will be able to navigate back to steps that don't make sense for them (e.g. the insurance survey)
  //
  const backButton =
    !alreadyEligible && currentStep.canNavigateBack ? handlePreviousStepNavigate : undefined

  return (
    <VideoCallFlowContext.Provider value={{ stepIndex, navigateToNextStep, registerCallback }}>
      <Box sx={styles.sticky}>
        <Header hideBorder backButton={backButton} />
        <FlowProgress />
      </Box>
      <GlobalTimer />
      {loading ? (
        <Box sx={styles.loaderWrapper}>
          <Loader />
        </Box>
      ) : (
        <StepComponent />
      )}
      <Typography sx={styles.securityNote} variant="subtitle2">
        <LockIcon sx={styles.lockIcon} />
        All details you share are secure and confidential
      </Typography>
    </VideoCallFlowContext.Provider>
  )
}

const styles = {
  securityNote: {
    color: 'brandText.light.main',
    fontSize: '0.75rem',
    letterSpacing: '-0.02em',
    lineHeight: 1.15,
    fontWeight: 400,
    width: '100%',
    paddingBottom: 2,
    paddingTop: 1,
    textAlign: 'center',
    marginTop: 'auto'
  },
  lockIcon: {
    verticalAlign: 'text-bottom',
    fontSize: '1rem',
    marginRight: 1
  },
  loaderWrapper: {
    margin: 'auto 0'
  },
  sticky: {
    backgroundColor: 'members.background',
    position: 'sticky',
    zIndex: 100,
    top: 0
  }
}
