export enum AppEnv {
  Development = 'development',
  Staging = 'staging',
  Production = 'production'
}

type Env = ImportMetaEnv & {
  REACT_APP_ENV: `${AppEnv}`
  REACT_APP_COMMIT_HASH?: string
  REACT_APP_BUGSNAG_API_KEY: string
  REACT_APP_API_ENDPOINT?: string
  REACT_APP_UNLEASH_URL: string
  REACT_APP_UNLEASH_CLIENT_KEY: string
  REACT_APP_GOOGLE_API_KEY: string
  REACT_APP_RECAPTCHA_SITE_KEY: string
  REACT_APP_GTM_CONTAINER_ID: string
  REACT_APP_STRIPE_PUBLISHABLE_KEY: string
  REACT_APP_GET_HEALTHIE_API_BASE_URL: string
  REACT_APP_GET_HEALTHIE_WEB_SOCKET_BASE_URL: string
  REACT_APP_NUTRISENSE_GET_HEALTHIE_SUPPORT_USER_ID: string
}

export const env = import.meta.env as Env
