import { Box, CircularProgress, SxProps, Theme } from '@mui/material'

export interface LoaderProps {
  containerStyle?: SxProps<Theme>
  style?: SxProps<Theme>
}

export const Loader = ({ containerStyle, style }: LoaderProps) => (
  <Box
    sx={{
      zoom: 0.7,
      display: 'flex',
      height: '100%',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      ...containerStyle
    }}
  >
    <CircularProgress sx={style} />
  </Box>
)
