import { ReactNode, useEffect } from 'react'
import FlagProvider from '@unleash/proxy-client-react'
import { client, buildContext } from 'utils/unleash'
import { useCurrentUserQuery } from 'types'

export const UnleashProvider = ({ children }: { children: ReactNode }) => {
  const { data: { currentUser } = {} } = useCurrentUserQuery()

  const userId = currentUser?.id
  const userEmail = currentUser?.email

  useEffect(() => {
    if (!userId) {
      return
    }
    if (client.getContext().userId === userId) {
      return
    }

    const context = buildContext(userId, userEmail)

    client.updateContext(context)
  }, [userId, userEmail])

  return (
    <FlagProvider unleashClient={client} startClient={false}>
      {children}
    </FlagProvider>
  )
}
