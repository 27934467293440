import { Box, Card, CardContent, Link, Typography } from '@mui/material'
import { NutrisenseLogo } from './NutrisenseLogo'

export const NotFound = () => {
  return (
    <Box sx={styles.container}>
      <Card sx={{ width: '100%' }}>
        <CardContent>
          <Box sx={styles.logo}>
            <NutrisenseLogo />
          </Box>
          <Box display="flex" justifyContent="center" sx={{ marginTop: 3 }}>
            <Typography variant="h4">404 - Page not found</Typography>
          </Box>
          <Box display="flex" justifyContent="center" sx={{ marginTop: 3 }}>
            <Link href="/">Go home</Link>
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}

const styles = {
  container: {
    flexDirection: 'column',
    margin: 'auto',
    height: '100vh',
    alignItems: 'center',
    paddingTop: '200px',
    maxWidth: '500px'
  },
  logo: {
    paddingTop: '20px',
    margin: 'auto',
    width: '60%'
  }
}
