import { forwardRef } from 'react'
import { Alert as MuiAlert, AlertProps as MuiAlertProps, AlertColor } from '@mui/material'
import { UiInlineAlert } from 'types'

export interface InlineAlertProps extends Omit<MuiAlertProps, 'severity'> {
  severity: AlertColor | 'neutral' | 'danger'
}

const UI_SEVERITY_MAP: { [key in UiInlineAlert]: AlertColor } = {
  [UiInlineAlert.Success]: 'success',
  [UiInlineAlert.Danger]: 'error',
  [UiInlineAlert.Info]: 'info',
  [UiInlineAlert.Neutral]: 'info',
  [UiInlineAlert.Warning]: 'warning'
}

const InlineAlert = forwardRef<HTMLDivElement, InlineAlertProps>((props, ref) => {
  const { severity, sx, ...muiAlertProps } = props
  const originalSx = Array.isArray(sx) ? sx : [sx]

  return (
    <MuiAlert
      ref={ref}
      severity={UI_SEVERITY_MAP[severity] || severity}
      sx={[
        styles.inlineAlert,
        (severity === 'neutral' || severity === 'info') && styles.neutral,
        ...originalSx
      ]}
      {...muiAlertProps}
    />
  )
})

const styles = {
  inlineAlert: {
    alignItems: 'center',
    '& .MuiAlert-icon': {
      marginRight: '16px'
    },
    '& .MuiAlert-message': {
      fontSize: '15px'
    }
  },
  neutral: {
    borderColor: 'info.lighter',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: 2,
    color: 'text.primary',
    backgroundColor: 'info.lightest',
    '& .MuiAlert-icon': {
      color: 'info.darkest'
    }
  }
}

InlineAlert.displayName = 'InlineAlert'

export { InlineAlert }
