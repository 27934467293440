import { useCallback, useRef } from 'react'
import { app } from 'config'
import { analytics } from 'config/segment'
import { isEqual } from 'lodash'

export const usePageTracking = () => {
  const currentPage = useRef('')
  const currentAttributes = useRef({})

  const trackPage = useCallback(
    (nextPage: string, pageAttributes?: Record<string, string | number | boolean>) => {
      if (currentPage.current === nextPage && isEqual(currentAttributes.current, pageAttributes)) {
        return
      }

      currentPage.current = nextPage
      currentAttributes.current = pageAttributes || {}
      analytics.page(nextPage, pageAttributes || {})

      if (!app.analyticsEnabled) {
        // eslint-disable-next-line no-console
        console.debug('trackPage', nextPage, pageAttributes)
      }
    },
    []
  )

  return trackPage
}
