import { useEffect, useState } from 'react'
import { Client } from '@bugsnag/browser'
import { useCurrentUserQuery } from 'types'

export const useBugsnagInit = (client: Client | undefined) => {
  const [BugsnagClient, setBugsnagClient] = useState<Client | undefined>(undefined)
  const { data: { currentUser } = {} } = useCurrentUserQuery()

  useEffect(() => {
    if (BugsnagClient) {
      const bugsnagUser = BugsnagClient.getUser()
      const { id, email, fullName } = currentUser || {}

      if (id !== bugsnagUser.id) {
        BugsnagClient.setUser(id, email, fullName)
      }
    } else {
      setBugsnagClient(client)
    }
  }, [BugsnagClient, client, currentUser])

  return { BugsnagClient }
}
