import { makeVar } from '@apollo/client'
import { AlertColor, SnackbarOrigin } from '@mui/material'

interface SnackBarContext extends Partial<SnackbarOrigin> {
  open: boolean
  severity?: AlertColor
  message?: string
  handleClose?: () => void
}

export const DEFAULT_SNACKBAR_CONTEXT: SnackBarContext = {
  open: false,
  severity: 'info',
  message: '',
  handleClose: () => {}
}

export const snackBarVisibleVar = makeVar<SnackBarContext>(DEFAULT_SNACKBAR_CONTEXT)

export const showSnack = (message: string, severity: AlertColor = 'info') => {
  snackBarVisibleVar({
    open: true,
    message,
    severity
  })
}
