import { FC, useEffect } from 'react'
import { Box } from '@mui/system'
import { Loader } from 'components'
import { useAuth } from 'context'
import { usePageTracking } from 'hooks/analytics'
import { useLocation } from 'react-router-dom'

export const PrivateRoute: FC<any> = ({ children }) => {
  const { isAuthorized, loading } = useAuth()
  const trackPage = usePageTracking()
  const location = useLocation()

  useEffect(() => {
    trackPage(location.pathname)
  }, [location, trackPage])

  if (isAuthorized && !loading) {
    return children
  }

  return (
    <Box sx={{ height: '100vh' }}>
      <Loader />
    </Box>
  )
}
