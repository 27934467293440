import { AppEnv, env } from './env'

const APP_STORE_URL = 'https://apps.apple.com/us/app/nutrisense/id1479100171'
const GOOGLE_PLAY_URL = 'https://play.google.com/store/apps/details?id=com.nutrisense'

export const app = {
  analyticsEnabled: env.REACT_APP_ENV !== AppEnv.Development,
  appStoreUrl: APP_STORE_URL,
  googlePlayUrl: GOOGLE_PLAY_URL
}
