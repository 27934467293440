import { CSSProperties } from 'react'
import { Theme, useTheme } from '@mui/material'
import { Confetti as ConfettiIcon, Gift as GiftIcon } from '@phosphor-icons/react'
import { UI_ICONS_MAPPING } from 'config/icons'
import { UiIcon } from 'types'

// The mui library icons aren't quite the same, so use phosphor
const PHOSPHOR_ICONS_MAPPING = {
  [UiIcon.Confetti]: ConfettiIcon,
  [UiIcon.Gift]: GiftIcon
}

export const CompleteIcon = ({ icon, style }: { icon: UiIcon; style?: CSSProperties }) => {
  const theme = useTheme()

  const PhosphorIcon = PHOSPHOR_ICONS_MAPPING[icon]

  if (PhosphorIcon) {
    return (
      <PhosphorIcon
        size={48}
        weight="duotone"
        color={theme.palette.brandPrimary.light.main}
        style={style}
      />
    )
  }

  const Icon = UI_ICONS_MAPPING[icon]
  return <Icon sx={[styles.muiIcon, style]} />
}

const styles = {
  muiIcon: (theme: Theme) => ({
    alignSelf: 'left',
    width: '48px',
    height: '48px',
    color: theme.palette.brandPrimary.light.main
  })
}
