import { useState } from 'react'
import { SearchParams } from 'location.types'
import { useSearchParams } from 'react-router-dom'
import { UserReferralPartner, useReferralPartnerQuery } from 'types'

const PARTNER_ID_KEY = 'partner'

export const useReferralPartner = () => {
  const [searchParams] = useSearchParams()
  const [partner, setPartner] = useState<UserReferralPartner | null>(
    localStorage.getItem(PARTNER_ID_KEY) as UserReferralPartner
  )

  const { data, loading } = useReferralPartnerQuery({
    variables: {
      partner
    },
    skip: !partner
  })

  const logoUrl = data?.referralPartner?.logoUrl

  const capturePartner = () => {
    const partnerKey = searchParams.get(SearchParams.Partner)?.toLowerCase()
    if (!partnerKey || partner) {
      return
    }
    if (!Object.values(UserReferralPartner).includes(partnerKey as UserReferralPartner)) {
      return
    }
    localStorage.setItem(PARTNER_ID_KEY, partnerKey)
    setPartner(partnerKey as UserReferralPartner)
  }

  return {
    partner,
    capturePartner,
    loading,
    logoUrl
  }
}
