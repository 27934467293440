import { useEffect } from 'react'
import { useGoogleTagManagerInit } from 'hooks/analytics/'
import { useGoogleTagManagerIdentifyUser } from 'hooks/analytics/googleTagManager/useGoogleTagManagerIdentifyUser'

import { useCurrentUserQuery, User } from 'types'

export const GoogleTagManager = () => {
  const { initialized } = useGoogleTagManagerInit()
  const identifyUser = useGoogleTagManagerIdentifyUser()

  const { data: { currentUser } = {} } = useCurrentUserQuery()

  useEffect(() => {
    if (!initialized || !currentUser) {
      return
    }

    identifyUser(currentUser as User)
  }, [currentUser, initialized, identifyUser])

  return null
}
