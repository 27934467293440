import { LatestUserAppointment, User } from 'types'
import { VideoCallFlow, VideoCallStep } from '../types'

export const findNextStep = (
  currentIndex: number,
  user: User,
  latestUserAppointment: LatestUserAppointment
): VideoCallStep => {
  const nextStep = VideoCallFlow[currentIndex + 1]
  if (!nextStep) {
    return VideoCallFlow[currentIndex]
  }
  if (nextStep.completedResolver(user, latestUserAppointment)) {
    return findNextStep(currentIndex + 1, user, latestUserAppointment)
  }

  return nextStep
}
