import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew'
import BedtimeIcon from '@mui/icons-material/BedtimeOutlined'
import BluetoothIcon from '@mui/icons-material/Bluetooth'
import CelebrationIcon from '@mui/icons-material/Celebration'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import FitnessCenterIcon from '@mui/icons-material/FitnessCenterOutlined'
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafetyOutlined'
import RestaurantIcon from '@mui/icons-material/RestaurantOutlined'
import ShowChartIcon from '@mui/icons-material/ShowChartOutlined'
import TrackChangesIcon from '@mui/icons-material/TrackChangesOutlined'
import WarningIcon from '@mui/icons-material/WarningAmber'
import { UiIcon } from 'types'

export const UI_ICONS_MAPPING: { [key in UiIcon]: typeof HealthAndSafetyIcon } = {
  [UiIcon.Coaching]: AccessibilityNewIcon,
  [UiIcon.Done]: CheckCircleOutlineIcon,
  [UiIcon.Food]: RestaurantIcon,
  [UiIcon.Glucose]: ShowChartIcon,
  [UiIcon.Goals]: TrackChangesIcon,
  [UiIcon.Health]: HealthAndSafetyIcon,
  [UiIcon.Lifecycle]: FitnessCenterIcon,
  [UiIcon.Sleep]: BedtimeIcon,
  [UiIcon.Confetti]: CelebrationIcon,
  [UiIcon.Warning]: WarningIcon,
  [UiIcon.Bluetooth]: BluetoothIcon
}
