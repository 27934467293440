import { createContext, ReactNode, useContext } from 'react'
import { SearchParams } from 'location.types'
import { useSearchParams } from 'react-router-dom'
import { getObject, LocalStorageKeys, setObject } from 'utils/storage'

type UtmParams = {
  [key: string]: string
}

interface UtmParamsProps {
  children: ReactNode
}

const UTM_BLACKLIST: string[] = Object.values(SearchParams)

const UtmParamsContext = createContext<UtmParams | null>(null)

export const useUtmParams = () => useContext(UtmParamsContext)

const getUtmParams = (searchParams: URLSearchParams): UtmParams => {
  const newUtm: UtmParams = {}

  searchParams.forEach((value, key) => {
    if (UTM_BLACKLIST.includes(key)) return
    newUtm[key] = value
  })

  return newUtm
}

export const UtmParamsProvider = ({ children }: UtmParamsProps) => {
  const [searchParams] = useSearchParams()

  const utmParams = getUtmParams(searchParams)

  const utmParamsFromStorage = getObject<UtmParams>(LocalStorageKeys.UtmParams)

  // We want to preserve the first captured utm
  const newUtm = {
    ...utmParams,
    ...utmParamsFromStorage
  }

  const contextValues = Object.keys(newUtm).length > 0 ? newUtm : null

  if (contextValues) setObject(LocalStorageKeys.UtmParams, contextValues)

  return <UtmParamsContext.Provider value={contextValues}>{children}</UtmParamsContext.Provider>
}
