import Bugsnag, { Client } from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { AppEnv, env } from 'config' // Adjust the import path as necessary

let bugsnagClient: Pick<Client, 'notify' | 'getUser' | 'setUser'> = {
  notify: (error: any) => {
    // eslint-disable-next-line no-console
    console.error(error)
  },
  getUser: () => ({}),
  setUser: () => {}
}

if (env.REACT_APP_ENV !== AppEnv.Development) {
  bugsnagClient = Bugsnag.start({
    apiKey: env.REACT_APP_BUGSNAG_API_KEY as string,
    plugins: [new BugsnagPluginReact()],
    releaseStage: env.REACT_APP_ENV
  })
}

export default bugsnagClient
