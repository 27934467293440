import { Theme } from '@mui/material'

export const globalStyles = (theme: Theme) => ({
  a: {
    color: theme.palette.brandPrimary.light.main,

    '&:hover': {
      color: theme.palette.brandSecondary.base.main
    }
  }
})
