import { makeVar } from '@apollo/client'
import { SurveysConfigKind } from 'types'

export interface CurrentSurvey {
  kind: SurveysConfigKind | null
  questionIndex: number
  length: number
}

const INITIAL_CURRENT_SURVEY = {
  kind: null,
  questionIndex: 0,
  length: 0
}

export const currentSurveyVar = makeVar<CurrentSurvey>(INITIAL_CURRENT_SURVEY)

export const setCurrentSurvey = (
  kind: SurveysConfigKind | null,
  questionIndex: number,
  length: number
) => {
  currentSurveyVar({
    kind,
    questionIndex,
    length
  })
}

export const resetCurrentSurvey = () => {
  currentSurveyVar(INITIAL_CURRENT_SURVEY)
}

export const updateCurrentSurveyState = (index: number, length: number) => {
  const currentSurvey = currentSurveyVar()
  currentSurveyVar({
    ...currentSurvey,
    questionIndex: index,
    length
  })
}
